<template>
 <div class="row justify-content-center">
    <div class="col-lg-5">
        <div class="card">

            <div class="card-body p-4">
                
                <div class="text-center w-75 m-auto">
                    <a href="index.html">
                        <span><img src="@/assets/images/logo-dark.png" alt="" height="22"></span>
                    </a>
                    <p class="text-muted mb-4 mt-3">Enter your email address and password to access admin panel.</p>
                </div>

                <form class="text-center digit-group mt-3" method="get" data-group-name="digits" data-autosubmit="false" autocomplete="off" id="form_phoneNumber">
                    <input type="text" id="digit-1" name="digit-1" data-next="digit-2"  />
                    <input type="text" id="digit-2" name="digit-2" data-next="digit-3" data-previous="digit-1" />
                    <input type="text" id="digit-3" name="digit-3" data-next="digit-4" data-previous="digit-2" />
                    <span class="splitter">&ndash;</span>
                    <input type="text" id="digit-4" name="digit-4" data-next="digit-5" data-previous="digit-3" />
                    <input type="text" id="digit-5" name="digit-5" data-next="digit-6" data-previous="digit-4" />
                    <input type="text" id="digit-6" name="digit-6" data-next="digit-7" data-previous="digit-5" />
                    <span class="splitter">&ndash;</span>
                    <input type="text" id="digit-7" name="digit-7" data-next="digit-8" data-previous="digit-6" />
                    <input type="text" id="digit-8" name="digit-8" data-next="digit-9" data-previous="digit-7" />
                    <input type="text" id="digit-9" name="digit-9" data-next="digit-10" data-previous="digit-8" />
                    <input type="text" id="digit-10" name="digit-10" data-previous="digit-9">
                    <input type="hidden" id="sign-in-form">
                </form>

                <form class="text-center digit-group mt-3 none" method="get" data-group-name="digits" data-autosubmit="false" autocomplete="off" id="form_OTP">
                    <input type="text" id="otp-1" name="otp-1" data-next="otp-2"  />
                    <input type="text" id="otp-2" name="otp-2" data-next="otp-3" data-previous="otp-1" />
                    <input type="text" id="otp-3" name="otp-3" data-next="otp-4" data-previous="otp-2" />
                    <input type="text" id="otp-4" name="otp-4" data-next="otp-5" data-previous="otp-3" />
                    <input type="text" id="otp-5" name="otp-5" data-next="otp-6" data-previous="otp-4" />
                    <input type="text" id="otp-6" name="otp-6" data-previous="otp-5" />
                </form>


                <!-- <form action="#">

                    <div class="form-group mb-3">
                        <label for="emailaddress">Email address</label>
                        <input class="form-control" type="email" id="emailaddress" required="" placeholder="Enter your email">
                    </div>

                    <div class="form-group mb-3">
                        <a href="pages-recoverpw.html" class="text-muted float-right"><small></small></a>
                        <label for="password">Password</label>
                        <input class="form-control" type="password" required="" id="password" placeholder="Enter your password">
                    </div>

                    <div class="form-group mb-3">
                        <div class="custom-control custom-checkbox">
                            <input type="checkbox" class="custom-control-input" id="checkbox-signin" checked>
                            <label class="custom-control-label" for="checkbox-signin">Remember me</label>
                        </div>
                    </div>

                    <div class="form-group mb-0 text-center">
                        <button class="btn btn-primary btn-block" type="submit"> Log In </button>
                    </div>

                </form> -->
            </div> <!-- end card-body -->
        </div>
        <!-- end card -->

        <div class="row mt-3">
            <div class="col-12 text-center">
                <p class="text-muted"> <a href="pages-register.html" class="text-muted ml-1">Forgot your password?</a></p>
                <p class="text-muted">Don't have an account? <a href="pages-register.html" class="text-dark font-weight-medium ml-1">Sign Up</a></p>
            </div> <!-- end col -->
        </div>
        <!-- end row -->

    </div> <!-- end col -->
</div>
</template>

<script>
import Swal from 'sweetalert2'
import firebase from 'firebase/app';
import "firebase/auth";
import 'busy-load'; 
import 'jquery-serializejson'
import $ from 'jquery'
export default
{
    name: 'app',
    data() {
        return {
            confirmationResult : false,
            recaptchaVerifier : false
        }
    },
    created()
    {
        // this.foo()
        // this.$store.commit('setLogin')
        // console.log(this.$store.state.isLogin)
    },
    mounted () {

        let $vm = this;
        $("#digit-1").val(0);
        $("#digit-2").val(1);
        $("#digit-3").val(2);
        $("#digit-4").val(3);
        $("#digit-5").val(4);
        $("#digit-6").val(5);
        $("#digit-7").val(6);
        $("#digit-8").val(7);
        $("#digit-9").val(8);
        this.recaptchaVerifier = new firebase.auth.RecaptchaVerifier('sign-in-form', {'size': 'invisible',});
        
        $('#form_phoneNumber,#form_OTP').find('input').each(function() {

            $(this).attr('maxlength', 1);
            $(this).on('keyup', function(e) {


                var parent = $($(this).parent());
                if(e.keyCode === 8 || e.keyCode === 37) {
                    var prev = parent.find('input#' + $(this).data('previous'));
                    
                    if(prev.length) {
                        $(prev).select();
                    }
                } else if((e.keyCode >= 48 && e.keyCode <= 57) || (e.keyCode >= 65 && e.keyCode <= 90) || (e.keyCode >= 96 && e.keyCode <= 105) || e.keyCode === 39) {
                    var next = parent.find('input#' + $(this).data('next'));
                    if(next.length) {
                        $(next).select();
                    }
                    else if(this.id == "digit-10")
                    {
                         let formdata = $('form#form_phoneNumber').serializeJSON();
                         let phoneNumber = ""
                         $.each(formdata,function(id,val){
                             phoneNumber += val
                         })
                        $vm.requestOTP(phoneNumber)
                    }
                    else if(this.id == "otp-6")
                    {
                        let formdata = $('form#form_OTP').serializeJSON();
                         let otp = ""
                         $.each(formdata,function(id,val){
                             otp += val
                         })
                        $vm.confirmOTP(otp)
                    }
                }
            });
        });
    },
    updated ()
    {
        console.log("ez")
    },
    methods :
    {
        async requestOTP(phoneNumber)
        {
            let $vm = this
            if (!phoneNumber.match(/^[0]{1}[0-9]{9}$/))
            {
                Swal.fire({icon: 'error',title: 'เบอร์โทรศัพท์ไม่ถูกต้อง',showConfirmButton: false,timer: 1500})
            }
            else
            {
                this.loadshow(true,false,".sign-in-from")
                $('form#form_phoneNumber input').attr("readonly","readonly")
                await firebase.auth().signInWithPhoneNumber(`+66${phoneNumber}`, this.recaptchaVerifier).then(function (confirmationResult) {
                    
                    $('form#form_phoneNumber,#text-phone').hide()
                    $('form#form_OTP,#text-otp').show()
                    $("#text-otp-number").text(phoneNumber)
                    $vm.confirmationResult = confirmationResult;
                }).catch(function (error) {
                    Swal.fire({icon: 'error',text: error.message,showConfirmButton: false,timer: 1500})
                });
                $('form#form_phoneNumber input').removeAttr("readonly")
                this.loadshow(false,false,".sign-in-from")
            }
        },
        async confirmOTP(otp)
        {
            let $vm = this

            let credential = firebase.auth.PhoneAuthProvider.credential(this.confirmationResult.verificationId,otp);
            if (!otp.match(/^[0-9]{6}$/))
            {
                this.g_errorMsg("หมายเลข OTP ไม่ถูกต้อง")
                $('form#form_OTP input').val("")
            }
            else
            {
                this.loadshow(true,false,".sign-in-from")
                $('form#form_OTP input').attr("readonly","readonly")
                await firebase.auth().signInWithCredential(credential).then(usercred => {
                console.log("usercred", usercred)
                    $("#loading").fadeIn();
                    // console.log("reidrect to ",$vm.$route.params.from)
                    $vm.$router.push({ path:  $vm.$route.params.from || "/" })
                //    window.location.href = $vm.$route.params.from;
                }, error => {
                    let message 
                    if (error.code === "auth/invalid-verification-code")
                        message = "รหัส OTP ไม่ถูกต้อง กรุณาลองใหม่อีกครั้ง"
                    else if (error.code === "auth/credential-already-in-use")
                        message = "ท่านเคยลงทะเบียนด้วยเบอร์โทรศัพท์นี้ไปแล้ว กรุณาเข้าสู่ระบบด้วยเบอร์โทรศัพท์อีกครั้ง"
                    else
                        message = error.message

                    console.log(error)
                    this.g_errorMsg(message)
                    this.loadshow(false,false,".sign-in-from")
                    $('form#form_OTP input').removeAttr("readonly","readonly")
                    $('form#form_OTP input').val("")
                })
            }
        },
        changeNumber()
        {
            $('form#form_phoneNumber,#text-phone').show()
            $('form#form_phoneNumber input,form#form_OTP input').val("")
            $('form#form_OTP,#text-otp').hide()
            $('form#form_OTP input').removeAttr("readonly","readonly")
        }
    }
    
}
</script>
<style>
.digit-group input {
		width: 30px;
		height: 40px;
		border: none;
		line-height: 50px;
		text-align: center;
		font-size: 24px;

		font-weight: 200;
	
		margin: 0 2px;
}
.digit-group .splitter {
		padding: 0 5px;
		color: white;
		font-size: 24px;
	}



</style>